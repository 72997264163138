<template>
  <v-row class="d-flex">
    <v-col 
      v-if="label"
      cols="4"
      class="d-flex py-0 flex-column"
    >
      <form-fields-label 
        :label="required ? `${label} *` : label"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <base-commentaire>
        {{ commentaire }}
      </base-commentaire>
    </v-col>
    <v-col
      :cols="label ? 8 : 12"
      class="py-0"
      :class="{ 'd-flex align-center': centered }"
    >
      <slot />
    </v-col>
  </v-row>
</template>

<script>
import FormFieldsLabel from "@/components/FormFieldsLabel.vue"
import BaseCommentaire from "@/components/BaseCommentaire.vue"

export default {
  name: 'BaseFormField',
  components: { 
    FormFieldsLabel,
    BaseCommentaire,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    commentaire: {
      type: String,
      default: "",
    },
  },
};
</script>