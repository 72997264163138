<template>
  <label class="form-label text-body-1 d-flex align-center">
    <span>{{ label }}</span>
    <v-tooltip 
      bottom
      color="cassiopee-purple"
    >
      <template v-slot:activator="{ on }">
        <base-button-icon
          v-if="infoIcon"
          icon="mdi-information-outline"
          :color="infoIconColor"
          :size="infoIconSize"
          class="ml-3 mb-2"
          v-bind="$attrs"
          v-on="on"
          @click.stop.prevent="$emit('iconClicked')"
          @mouseover="$emit('iconHover')"
        />
      </template>
      <span class="text-caption">Cliquer pour plus d'informations</span>
    </v-tooltip>
   
  </label>
</template>

<script>
import BaseButtonIcon from '@/components/BaseButtonIcon.vue'
export default {
  name: "FormFieldsLabel",
  components: {
    BaseButtonIcon
  },
  props: {
    label: {
      type: String,
      required: true
    },
    infoIcon: {
      type: Boolean,
      default: false
    },
    infoIconColor: {
      type: String,
      default: 'black'
    },
    infoIconSize: {
      type: String,
      default: '20'
    }
  },
}
</script>

<style lang="scss" scoped>

.form-label {
  max-width: 250px;
}
</style>